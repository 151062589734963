@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  text-align: center;
   -webkit-text-size-adjust: none;
}
#root {
    width: 100%;
}

.imageCover {
  max-width: 400px;
  height: auto;
  margin-bottom: -40px;
}
.container {
  max-width: '100%'  //This will make container to take screen width
}
.progress-bar{
  background-color:#fbf139 !important;
  color:#000000 !important;
  font-size:12px;
}
.form{
  height: 100vh;
}
.btn_right{
  float: right;
}
.btn_left{
  float: left;
}
.div_text_left{
  text-align:left !important;
  float: left;
}
.col-centered{
    float: none;
    margin: 0 auto;
}
.my_select {
  width: 100px;
  white-space: normal;
  word-break: break-all;
}
select {width: 100% !important;}
.dropdown-toggle,
.dropdown-menu {
  width: 100%;
  white-space: normal;
  word-break: break-all;
}
.fixfooter{
  width:100%;
  position:fixed;
  bottom:10px;
  margin: 0 auto;
}
.meetingPreview{
  width:100%;
  background-color:#fbf139 !important;
  margin:10px 10px;
}
